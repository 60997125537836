const { countBy } = require("lodash");

$(document).ready(function () {
  // Minimalize menu
  $('.navbar-minimalize').on('click', function (event) {
    event.preventDefault();
    $("body").toggleClass("sidebar-collapsed");
    SmoothlyMenu();
  });

  $('#advanced_search').on('click', function (event) {
    event.preventDefault();
    if($('#advanced_search')[0].outerText == 'ADVANCED SEARCH') {
      $('.advanced_search_row').removeClass('invisible');
      $('.simple_search_row').addClass('invisible');

      $('.advanced_search_row').css('height', '108px');
      $('.simple_search_row').css('height', '0px');

      $('#advanced_search').text('Simple Search');
      $('#search_button').css('height', '34px');
    } else {
      $('.simple_search_row').removeClass('invisible');
      $('.advanced_search_row').addClass('invisible');

      $('.advanced_search_row').css('height', '0px');
      $('.simple_search_row').css('height', '54px');

      $('#advanced_search').text('Advanced Search');
      $('#search_button').css('height', '50px');
    }
  })

  $('#request_strains').on('click', function (event) {
    event.preventDefault();

    if($('#cartTable').length > 0) {
      $('#cart_button_row').addClass('invisible');
      $('#cart_button_row').css('height', '0px');

      $('#cart_request_form').removeClass('invisible');
      $('#cart_request_form').css('height', '530px');
    }
  })
});
